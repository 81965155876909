var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"dense":"","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.status_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaCupomStatusOptions(), function (i) { return i.id == item.status_id; }) .name)+" "),(item.agendamento)?_c('span',[_vm._v(" - "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(item.agendamento))+" ")]):_vm._e()]}}])}),(_vm.numeroRepetido().length > 0)?_c('v-card',{staticClass:"ma-3"},[_c('v-card-title',[_c('span',{staticStyle:{"color":"red"}},[_vm._v(" Existe mais de um cupom com este telefone ")])]),_c('v-data-table',{attrs:{"items":_vm.numeroRepetido(),"headers":_vm.headersNumerosRepetidos,"footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.ultimo_contato",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}}],null,false,590219951)})],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.cupomLigacaoStatus <= 0 && _vm.propPermiteLigar)?_c('v-btn',{attrs:{"color":"green","dark":"","rounded":""},on:{"click":function($event){return _vm.cupomLigar()}}},[_vm._v(" ligar ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.cupomLigacaoStatus <= 0 && _vm.propPermiteLigar)?_c('v-btn',{staticStyle:{"background-color":"#E6EDF4","color":"#00B854"},attrs:{"dark":"","rounded":""},on:{"click":_vm.abrirWhatsApp}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-whatsapp")]),_vm._v(" WhatsApp ")],1):_vm._e()],1)],1),(_vm.cupomLigacaoStatus > 0)?_c('v-row',{staticStyle:{"justify-content":"center"}},[_c('div',[_c('v-col',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.cupomLigacaoStatus),callback:function ($$v) {_vm.cupomLigacaoStatus=$$v},expression:"cupomLigacaoStatus"}},[_c('v-radio',{attrs:{"label":"Caixa postal","value":"14"}}),_c('v-radio',{attrs:{"label":"Telefone não existente","value":"15"}}),(_vm.propParceriaCupom.status_id == 3)?_c('v-radio',{attrs:{"label":"Desistiu","value":"6"}}):_vm._e(),_c('v-radio',{attrs:{"label":"Não atendeu","value":"2"}}),(_vm.propParceriaCupom.status_id != 10)?_c('v-radio',{attrs:{"label":"Sem interesse","value":"7"}}):_vm._e(),(_vm.propParceriaCupom.status_id == 10)?_c('v-radio',{attrs:{"label":"Desistiu da visita","value":"6"}}):_vm._e(),(_vm.propParceriaCupom.status_id == 10)?_c('v-radio',{attrs:{"label":"Confirmou visita","value":"9"}}):_vm._e(),(_vm.propParceriaCupom.status_id == 10)?_c('v-radio',{attrs:{"label":"Reagendou","value":"4"}}):_vm._e(),(_vm.propParceriaCupom.status_id != 10)?_c('v-radio',{attrs:{"label":"Agendou visita","value":"3"}}):_vm._e(),(_vm.propParceriaCupom.status_id == 3)?_c('v-radio',{attrs:{"label":"Confirmou","value":"5"}}):_vm._e(),_c('v-radio',{attrs:{"label":"Ligar outro momento","value":"11"}})],1)],1)],1)]):_vm._e(),(
    _vm.cupomLigacaoStatus > 1 &&
    _vm.cupomLigacaoStatus != 2 &&
    _vm.cupomLigacaoStatus != 14 &&
    _vm.cupomLigacaoStatus != 15 //2 - nao atendeu, 14 - Caixa postal, 15 - Telefone não existente
  )?_c('div',[_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Observações","rows":"3"},model:{value:(_vm.observacoesLigacao),callback:function ($$v) {_vm.observacoesLigacao=$$v},expression:"observacoesLigacao"}})],1)],1),_c('validation-observer',{ref:"agendamentoObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var invalid = ref.invalid;
return [(
        ['3', 3 ,'4', 4 ,'11', 11].includes(_vm.cupomLigacaoStatus)
      )?_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Data","vid":"data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('date-field-component',{attrs:{"propTitle":"Data","error-messages":errors},model:{value:(_vm.scheduleDatePicker),callback:function ($$v) {_vm.scheduleDatePicker=$$v},expression:"scheduleDatePicker"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Hora","vid":"hora","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],attrs:{"prepend-icon":"mdi-clock","label":"Horário","error-messages":errors},model:{value:(_vm.scheduleTime),callback:function ($$v) {_vm.scheduleTime=$$v},expression:"scheduleTime"}})]}}],null,true)})],1)],1):_vm._e()]}}],null,false,2213397393)})],1):_vm._e(),(_vm.cupomLigacaoStatus > 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"success",attrs:{"disabled":_vm.saveLoading || (_vm.cupomLigacaoStatus == 7 && !_vm.motivoPerdaId)},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" salvar ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('PerdaComercialComponent',{attrs:{"propDisable":_vm.cupomLigacaoStatus != 7,"propTipo":"parceria_cupom","propId":_vm.propParceriaCupom.id,"propDescricaoPerda":_vm.propParceriaCupom.motivo_perda_descricao,"propMotivoPerdaId":_vm.propParceriaCupom.motivo_perda_id,"propDataPerda":_vm.propParceriaCupom.data_perda},model:{value:(_vm.motivoPerdaId),callback:function ($$v) {_vm.motivoPerdaId=$$v},expression:"motivoPerdaId"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }